@use '../variables' as *;

.operational-planning {
  .planning-header {
    .flatpickr-input {
      display: none;
    }

    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 220px !important; // scss-lint:disable ImportantRule
    }

    .planning-variable-value-filter {
      .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
        width: 100% !important; // scss-lint:disable ImportantRule
      }
    }

    .date-range-datepicker-input-group {
      border-radius: 4px;
    }

    .form-control-date-range,
    .date-range-datepicker-custom-input {
      background-color: $white;
    }

    .form-control-date-range {
      height: 35px !important; // scss-lint:disable ImportantRule
    }

    .date-modal {
      .modal-dialog {
        width: 335px;
      }
    }

    .planning-variable-button {
      border-color: $gray-400;
      border-radius: 4px;
    }

    .planning-filter-summary-button {
      color: $black;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
    }

    .daterangepicker {
      .drp-buttons > .cancelBtn { // scss-lint:disable SelectorFormat
        background-color: $gray-500;
        border-color: $gray-500;
        color: $white;
      }

      .available.in-range {
        background-color: $red-02;
      }

      .available.active,
      .available.active:hover {
        background-color: $red-01;
        color: $white;
      }

      .date-range-datepicker-input-group {
        display: none;
      }
    }

    .planning-variable-filter {
      .dropdown-toggle {
        border-radius: 4px 0 0 4px;
      }

      .planning-dropdown {
        .dropdown-toggle {
          border-radius: 4px !important; // scss-lint:disable ImportantRule
        }
      }

      .btn-primary {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .planning-table {
    a,
    a:hover {
      color: $black;
    }

    .table {
      display: block;
      max-height: 84vh;
      overflow-x: scroll;
      overflow-y: scroll;
      table-layout: fixed;

      thead {
        position: sticky;
        top: 0;
        z-index: 11;

        th {
          border-top: 0;
        }
      }
    }

    @media (max-width: 768px) {
      td {
        min-width: 105px;
      }
    }

    @media (min-width: 768px) {
      td {
        min-width: 13vw;
        width: calc(100%/8);
      }
    }

    .first-row,
    .first-column {
      background-color: $gray-100;
    }

    .first-column {
      font-weight: 500;
    }

    .text-center {
      text-align: center;
    }

    .planning-appointment-item {
      z-index: 2000;

      .planning-check {
        background-color: $green-01;
        border-left: 5px solid $green-100;
        width: 100%;
      }

      .planning-warning {
        background-color: $orange-01;
        border-left: 5px solid $orange-100;
        width: 100%;
      }

      .appointment-date {
        font-weight: 500;
      }

      .add-appointment {
        align-items: center;
        background-color: $gray-100;
        display: block;
        justify-content: center;
        visibility: hidden;
        width: 100%;
      }
    }

    @media (min-width: 768px) {
      td:hover .planning-appointment-item .add-appointment {
        visibility: visible;
      }
    }

    .no-appointments {
      height: 50vh;
    }

    .pagy-pagination {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    .pagy-prev {
      border-radius: 4px 0 0 4px;
    }

    .pagy-next {
      border-radius: 0 4px 4px 0;
    }

    .pagy-page,
    .pagy-prev,
    .pagy-next {
      background-color: $white;
      border: 1px solid $gray-400;
      cursor: pointer;
      padding: 10px 15px;
    }

    .pagy-page.active {
      background-color: $red-01;
      border-color: $red-01;
      color: $white;
    }

    .pagy-prev:disabled,
    .pagy-next:disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}
