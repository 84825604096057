@use '../variables' as *;

.availabilities-calendar {
  > div {
    height: calc(100vh - 140px);
    width: 100%;
  }

  @each $name, $value in $colors {
    .event-color-#{$name} {
      &.fc-v-event {
        background-color: $value;
        border: 1px solid $value;
      }

      // scss-lint:disable DuplicateProperty
      .fc-daygrid-event-dot {
        border: 4px solid $value;
        border: calc(8px / 2) solid $value;
        border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid $value;
      }
      // scss-lint:enable DuplicateProperty
    }
  }
}
