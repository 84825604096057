// Color System
$white: #fff;
$gray-50: #ddd;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-250: #ebebeb;
$gray-275: #d5d4df;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-410: #c0c3cc;
$gray-500: #aaa;
$gray-600: #999;
$gray-700: #777;
$gray-800: #575757;
$gray-900: #4d4d4d;
$black: #444;
$black-100: #333;

$blue: #42a5f5;
$indigo: #3b51b5;
$purple: #9c27b0;
$pink: #e91e63;
$red: #ef5350;
$red-01: #e35543;
$red-02: #fbeeec;
$lightness-red: #c71612;
$orange: #fdb244;
$orange-100: #df9234;
$orange-01: #fdf4e7;
$yellow: #ffca28;
$green-01: #eefbf4;
$green-100: #2cc675;
$green: #9ccc65;
$teal: #009688;
$cyan: #26c6da;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-900,
);
