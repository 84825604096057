// scss-lint:disable IdSelector, ImportantRule

@use '../variables' as *;

.agenda-calendar {
  .agenda-dropdown {
    .dropdown-toggle {
      background-color: $white;
      border-color: $gray-250;
      border-radius: 4px;
    }
  }

  .agenda-dropdown:hover {
    .dropdown-toggle {
      border-color: $red-01;
      color: $black;
    }
  }

  .agenda-controls-btn {
    background-color: $white;
    border-color: $gray-250;
    border-radius: 4px;
    color: $black;
    font-size: 14.6px;
    font-weight: 500;
  }

  .btn-availability {
    background-color: $gray-100;
    border-radius: 5px;
  }

  .btn-sidebar {
    background-color: $white;
    border-color: $gray-250;
    border-radius: 5px;
  }

  .btn-sidebar:hover {
    background-color: $gray-250;
  }

  .btn-availability:hover {
    background-color: $red-02;
    color: $red-01;
    text-decoration: none;
  }

  .agenda-controls-btn:hover {
    background-color: $gray-250;
    border-color: $gray-250;
  }

  @media (max-width: 830px) {

    .agenda-headertoolbar {
      padding-bottom: 0 !important;
    }

    .calendar-order {
      order: 13;
      padding-right: 15px !important;
    }

    .agenda-dropdown {
      padding-bottom: .5rem;
      padding-right: 0 !important;
      width: 100% !important;
    }

    .agenda-calendar-dropdown {
      margin-right: 0 !important;
    }

    .agenda-calendar-controls-btn {
      display: none;
    }

    .bootstrap-select {
      margin-right: 0 !important;
    }

    .bootstrap-select > select {
      left: 0;
    }

    .sidebar-selectors {
      display: block;
    }

    .agenda-sidebar {
      padding-left: 15px !important;

      .sidebar-btn {
        display: none;
      }
    }

    .header-calendar-title {
      display: none;
    }

    .agenda-controls-btn {
      margin-right: auto;
    }

    .header-calendar-date {
      padding-right: .5rem !important;
    }

    .agenda-minicalendar {
      display: none;
    }
  }

  @media (min-width: 830px) {

    .agenda-datepicker {
      display: none;
    }

    .calendar-mobile-only {
      display: none;
    }

    .sidebar-availability {
      padding-bottom: .5rem !important;
      padding-top: .5rem !important;
    }
  }

  .agenda-controls {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    .controls-btn {
      margin: 0 10px;

      i {
        color: $black;

        &:hover {
          color: $lightness-red;
        }
      }
    }

    h4 {
      margin-bottom: 4px;
      text-transform: capitalize;
    }

    .header-calendar-title {
      font-size: 22.5px;
      font-weight: 500;
      padding-right: 20px;

      .header-calendar-icon {
        color: $red-01;
        padding-right: 8px;
      }
    }

    @media (max-width: 830px) {
      .header-calendar-title {
        width: 100%;
      }
    }

    .header-calendar-date {
      color: $black;
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .more-settings-collapse {
    line-height: 1;
    padding-bottom: 5px;

    .custom-control-label {
      line-height: 1.4;
    }
  }

  .custom-control-label-before::before {
    border: 0 !important;
  }

  .fc {
    height: calc(100vh - 188px);
    width: 100%;
  }

  @media (max-width: 830px) {

    .fc {
      margin-right: 15px;
    }
  }

  .agenda-selectors .bootstrap-select { margin-right: 4px; }
  .fc-event-past { opacity: .7; }

  @each $name, $value in $colors {
    .event-color-#{$name} {
      &.fc-v-event,
      &.fc-h-event {
        background-color: $value;
        border: 1px solid $value;
      }

      // scss-lint:disable DuplicateProperty
      .fc-daygrid-event-dot {
        border: 4px solid $value;
        border: calc(8px / 2) solid $value;
        border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid $value;
      }
      // scss-lint:enable DuplicateProperty
    }

    @media (min-width: 830px) {

      .col-sidebar {
        flex: 0 0 20%;
        max-width: 295px;
      }
    }

    @media (max-width: 830px) {

      .col-sidebar {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .event-canceled,
  .event-refused {
    opacity: .7;

    .fc-event-title { text-decoration: line-through; }
  }

  .fc-list-day-cushion {
    background-color: $gray-250 !important;
    font-size: 14px;
    font-weight: 600;
  }

  .table-header {
    background-color: $white !important;
    font-size: 14px;
    font-weight: 500;
    text-align: justify;

    > div { padding: 8px 14px; }
  }


  .agenda-sidebar {

    .sidebar-calendar {
      color: $black;
      font-size: .800rem;
      font-weight: 500;

      &:hover {
        color: $red;
      }
    }

    .dropdown-toggle {
      font-size: .800rem;
    }

    .rmdp-shadow {
      box-shadow: unset;
    }

    @media (max-width: 830px) {
      .rmdp-container {
        border-radius: 5px;
        text-align: center;
        width: 100% !important;

        .input-group {
          margin-bottom: 0;

          .input-group-text {
            background-color: $white;
            border-bottom-left-radius: 5px;
            border-color: $gray-250;
            border-right: transparent;
            border-top-left-radius: 5px;
          }

          input {
            border-bottom-right-radius: 5px;
            border-color: $gray-250;
            border-left: transparent;
            border-top-right-radius: 5px;
            padding-left: 0;
          }
        }
      }

      .rmdp-wrapper {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .rmdp-header {
      margin-top: 0;
      padding: 0;

      .rmdp-header-values {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-right: auto;
      }

      .rmdp-arrow {
        border: solid $black;
        border-width: 0 2px 2px 0;
        padding: 2px;
      }

      .rmdp-arrow-container:hover {
        background-color: $red-02;
      }
    }

    .rmdp-calendar {
      padding: 0;

      .rmdp-week-day {
        color: $black;
      }

      .rmdp-day-picker {
        padding: 0;

        .rmdp-day {
          border: 1px solid $gray-250;
          border-collapse: collapse;
          border-left: 0;
          border-radius: 0;
          border-right: 1px solid $gray-250;
          border-top: 0;
          height: 35px;
          margin-bottom: 0;
          width: 35px;

          span:hover {
            background-color: $red-02;
            color: $black;
          }

          .rmdp-today {
            background-color: transparent;
          }

          &.rmdp-today span {
            background-color: transparent;
            color: $red-01;
          }

          // scss-lint:disable NestingDepth
          &.rmdp-selected {
            background-color: $red-01;
            border-radius: 0;

            &.rmdp-today span {
              border-radius: 0;
              color: $white;

              &:hover {
                background-color: transparent;
                color: $white;
              }
            }

            span {
              background-color: transparent;
              box-shadow: unset;
            }
          }
          // scss-lint:enable NestingDepth

          &:hover {
            background-color: $red-02;
            border-radius: 0;
            color: $black;
          }

        }

        .rmdp-week > :last-child {
          border-right: 0;
        }

        .rmdp-week:nth-of-type(1n + 2) > :last-child {
          border-right: 1px solid $gray-250;
        }

        .rmdp-week:nth-of-type(1n + 2) > :first-child {
          border-left: 1px solid $gray-250;
        }

        .rmdp-week:nth-of-type(0n + 2) {
          border-top: 1px solid $gray-250;
        }

        .rmdp-week-day {
          font-size: 10.5px;
        }
      }

      .rmdp-week {
        background-color: $white;
        border-bottom: none !important;
        border-collapse: collapse;
        border-radius: 0;
        margin-bottom: 0;
      }

      .rmdp-month-picker,
      .rmdp-year-picker {
        bottom: 0;
        left: 0;
        margin-top: 5px;
        right: 0;
        top: 0;
        width: 250px;

        .rmdp-ym {
          border-bottom: 1px solid $gray-250;
          border-left: 1px solid $gray-250;

          .rmdp-day {
            border-right: 1px solid $gray-250;
            height: 100%;
            margin: 0;

            // scss-lint:disable NestingDepth, SelectorDepth
            &.rmdp-selected {
              background-color: $red-01;

              span {
                background-color: transparent;
                box-shadow: unset;
              }
            }
            // scss-lint:enable NestingDepth, SelectorDepth
          }
        }

        .rmdp-ym:first-child {
          border-top: 1px solid $gray-250;
        }

        .rmdp-day:hover {
          background-color: $red-02;
          border-radius: 0;
          color: $black;
        }

        .rmdp-day span:hover {
          background-color: $red-02;
          color: $black;
        }

        .rmdp-day.rmdp-today {
          background-color: transparent;

          span {
            background-color: transparent;
            color: $red-01;
          }
        }

        .rmdp-day.rmdp-selected {
          background-color: $red-01;
          border-radius: 0;

          span {
            background-color: transparent;
            box-shadow: unset;

            // scss-lint:disable NestingDepth
            &:hover {
              background-color: transparent;
              color: $white;
            }
            // scss-lint:enable NestingDepth
          }

          &.rmdp-today span {
            border-radius: 0;
            color: $white;
          }
        }
      }

      .rmdp-month-picker {
        left: -5px;
      }

    }

    .sidebar-subtitle {
      color: $red-01;
      font-size: 14px;
      font-weight: 500;
    }

    .custom-control {
      &.agenda-custom-switch {
        padding-left: 0;

        .agenda-custom-switch-control-description {
          color: $black;
          font-size: .800rem;
          font-weight: 500;
        }

        // scss-lint:disable NestingDepth
        .agenda-custom-switch-control-input {
          display: none;

          &:active~.agenda-custom-switch-control-indicator {
            &::after {
              width: 20px;
            }
          }

          &:checked {
            ~.agenda-custom-switch-control-indicator {
              border: 10px solid $red;

              &::after {
                left: 4px;
                top: -8px;
              }
            }

            &:active~.agenda-custom-switch-control-indicator {
              &::after {
                left: 0;
              }
            }
          }
        }
        // scss-lint:enable NestingDepth

        .agenda-custom-switch-control-indicator {
          background: $white;
          border: 2px solid $gray-50;
          border-radius: 16px;
          display: inline-block;
          height: 20px;
          margin: 0 10px;
          position: relative;
          top: 4px;
          transition: .3s;
          width: 32px;

          &::after {
            background: $white;
            border-radius: 16px;
            box-shadow: 0 0 2px $gray-500, 0 2px 5px $gray-600;
            content: '';
            display: block;
            height: 16px;
            left: 0;
            position: absolute;
            top: 0;
            transition: .3s;
            width: 16px;
          }
        }
      }
    }
  }

  .custom-list-spacing-row {
    height: 30px;
  }

  .fc-list-event {
    position: relative;
    z-index: -100;
  }

  .fc-list-event-title {
    border-left: 0 !important;
    padding-left: 7px !important;
  }

  .fc-popover-body {
    background-color: $white;
  }

  @media (max-width: 830px) {
    .fc-scoller {
      overflow: auto !important;
    }
  }

}

#agenda-new-appointment-modal,
#agenda-edit-appointment-modal {
  .form-group {
    position: relative;

    > .form-control { padding-left: 30px; }

    i {
      color: $gray-600;
      cursor: help;
      font-size: 16px;
      left: 0;
      position: absolute;
      top: 10px;
    }
  }

  #appointment-start,
  #appointment-end,
  #appointment-revive-at {
    margin-left: 30px;
    padding-left: .375rem;
    width: calc(100% - 30px);
  }

  #input-location,
  #input-reminder-delay {
    margin-left: 30px;
    padding: 0 10px;
    width: calc(100% - 30px);
  }
}

.fc {

  .fc-scrollgrid.table-bordered {
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
  }

  .fc-col-header-cell {
    border: 1px solid transparent;
  }

  .fc-scrollgrid-sync-inner {
    background-color: transparent;
    text-transform: capitalize;
  }

  .table-bordered thead th {
    border-right: 1px solid transparent;
  }


  .fc-day-today:not(.fc-date-selected) {
    background-color: $red-02 !important;
  }

  .table-bordered tbody {
    border-left: 1px solid $gray-250;
    border-top: 1px solid transparent;

    td {
      border-left: 1px solid $gray-250;
      border-top: 1px solid $gray-250;

      .fc-daygrid-day-number {
        color: $black;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .fc-day-past {
    background-color: transparent;

    .fc-daygrid-day-frame .fc-daygrid-day-number {
      color: $gray-410;
    }
  }

  .fc-daygrid-day-events {
    .fc-event-desc,
    .fc-daygrid-event {
      align-items: baseline !important;
      text-overflow: ellipsis;
      $white-space: break-spaces;
    }

    .fc-event-time {
      margin-left: 3px;
    }

    .event-refused {
      .fc-event-time {
        margin-left: 0;
      }
    }

    .fc-daygrid-event .fc-event-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fa-times::before {
      content: "" !important;
    }
  }

}
// scss-lint:enable IdSelector, ImportantRule
